body.login {
	background: url(../img/overlay.png) top left / cover;
	h1 {
		a {
			width: 320px;
			height: 55px;
			background: url(../img/logo.png) no-repeat center center / contain;
		}
	}
	form {
		box-shadow: none;
	}
}

#login {
	box-shadow: none;
}
